<template>
  <section id="userListDown" class="navbar-header expanded">
    <hr class="mt-0" />
    <div class="user-data" @click="view = !view" v-ripple.400="'rgba(113, 102, 240, 0.15)'">
      <b-avatar class="avatar" :text="username" variant="light-primary" />
      <div>
        <h4 class="mb-0">{{ userInfo.user && userInfo.user.username }}</h4>
      </div>
      <feather-icon size="16" :icon="iconState" class="ml-50" />
    </div>
    <div v-if="view" class="mt-2">
      <b-button :to="{ name: this.isAdmin ? 'user-settings-admin' : 'user-settings' }" variant="outline-dark" block>
        <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
        Settings
      </b-button>

      <b-button :to="{ name: 'api-manage' }" variant="outline-dark" block>
        API Manage
      </b-button>
      <b-button @click="onClickWhatsappSettings" variant="outline-dark" block>
        Whatsapp Setting
      </b-button>

      <b-button @click="logout" variant="outline-dark" block>
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        Logout
      </b-button>
    </div>
    <hr />
    <SendVerificationCodeDialog :id="sendVerificationModalId"
      @modalHidden="onHiddenModal" @codeSent="onCodeSent" />
    <VerifyCodeDialog :id="verifyCodeModalId" :mobileNumber="wpMobileNumber" @changeNumber="onClickWhatsappSettings" />
  </section>
</template>

<script>
import { BButton, BAvatar } from "bootstrap-vue";
import SendVerificationCodeDialog from "@/components/whatsapp/SendVerificationCodeDialog.vue";
import VerifyCodeDialog from "@/components/whatsapp/VerifyCodeDialog.vue";
import Auth from "@/services/auth.service.js";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapState } from "vuex";
export default {
  data: () => ({
    view: false,
    wpMobileNumber: '',
    sendVerificationModalId: 'modal-send-to-whatsapp-user-dropdown',
    verifyCodeModalId: 'modal-verification-code-user-dropdown'
  }),
  methods: {
    onHiddenModal() {
      console.log('hidden..')

    },
    onClickWhatsappSettings() {
      this.showSendVerificationDialog = true;
      this.$bvModal.show(this.sendVerificationModalId)
    },
    async logout() {
      await Auth.logout();
    },
    onCodeSent(value) {
      this.wpMobileNumber = value.mobileNumber;
      this.$bvModal.show(this.verifyCodeModalId)
    },
  },
  computed: {
    ...mapState({ userInfo: "user" }),
    ...mapGetters(["isAdmin"]),
    username() {
      const fullName = this.userInfo.user?.name || "";
      const names = fullName.split(" ");
      return names.length >= 2 ? `${names[0][0]}${names[1][0]}` : "";
    },
    iconState() {
      return this.view ? "ArrowUpIcon" : "ArrowDownIcon";
    },
  },
  components: { BAvatar, BButton, SendVerificationCodeDialog, VerifyCodeDialog },
  directives: { Ripple },
};
</script>

<style lang="scss" scoped>
#userListDown {
  padding: 16px;
  height: auto;

  .avatar {
    margin-right: 20px;
  }

  .user-data {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    h4,
    h6 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>