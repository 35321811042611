<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    @show="openList"
    @hide="hideList"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="totalSeen > 0 ? totalSeen : ''"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>
    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-button
          @click="clearAll"
          class="mr-1"
          variant="flat-danger"
          size="sm"
        >
          Clear All
        </b-button>
        <b-badge v-if="totalSeen > 0" pill variant="light-primary">
          {{ totalSeen }} NEW
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <div
        class="notify-txt"
        v-for="(n, idx) in notifies"
        :key="`notify${idx}`"
      >
        <b-media class="media-content">
          <div>
            <p class="media-heading">
              <span class="font-weight-bolder" v-text="n.notification.title" />
            </p>
            <small
              class="notification-text"
              v-html="n.notification.description"
            />
          </div>
          <b-badge v-if="!n.is_seen" pill variant="light-primary">
            NEW
          </b-badge>
        </b-media>
      </div>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>
<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { mapState, mapGetters } from "vuex";
import { mapMutations } from "vuex";
import Notify from "@/services/notification.service.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data: () => ({
    notifies: [],
    totalSeen: 0,
  }),
  directives: { Ripple },
  async mounted() {
    // if (this.isAdmin) return;
    await this.load();
    console.log("Listening for notification", this.userID);
    // Listen for notification
    // Start listen for channel - WEBSOCKET
    const notifyEvent = (userId = "") =>
      this.$pusher
        .subscribe(`NotifyChannel${userId}`)
        .bind(
          `NotifyEvent`,
          async ({ title, description, updatedCredit = null }) => {
            await this.load();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: title,
                icon: "BellIcon",
                text: description,
                variant: "success",
              },
            });

            if (updatedCredit) {
              console.log(updatedCredit);
              this.SET_CREDIT(updatedCredit);
            }
          }
        );
    notifyEvent();
    notifyEvent(this.userID);
  },
  destroyed() {
    this.$pusher.unsubscribe("NotifyChannel");
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState({ user: "user" }),
    userID() {
      return this.user?.user.id;
    },
  },
  methods: {
    ...mapMutations(["SET_CREDIT"]),
    hideList() {
      this.load();
    },
    async openList() {
      await Notify.readAll();
    },
    async load() {
      const { list, totalSeen } = await Notify.my();
      this.notifies = list;
      this.totalSeen = totalSeen;
    },
    async clearAll() {
      await Notify.clearAll();
      this.load();
    },
  },
  setup() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    ToastificationContent,
  },
};
</script>

<style lang="scss" scoped>
.notify-txt {
  cursor: default;
}
.dropdown-header {
  display: flex;
  align-items: center;
}
.media-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
